const games = [
    { name: 'Star Blaster', imageUrl: '/images/defenders.jpeg', link: 'https://gameschakra.com/testing/star%20Blaster/' },
    { name: 'Number Snake', imageUrl: '/images/numbersnake2.webp', link: 'https://gameschakra.com/testing/NumberSnake/v2/' },
    // { name: 'Ninja Pumpkin', imageUrl: '/images/Ninjapumpkingpt.webp', link: 'https://gameschakra.com/testing/Ninja%20Pumpkin/' },
    { name: 'Loonie Birds', imageUrl: '/images/Looniebirdsgpt.webp', link: 'https://gameschakra.com/testing/loonieBirds/v6/' },
    { name: '90-Degrees', imageUrl: '/images/90degreesgpt.webp', link: 'https://gameschakra.com/testing/90%20Degrees/' },
    { name: 'Helicopter Fuel', imageUrl: '/images/Helicopterfuelgpt.jpeg', link: 'https://gameschakra.com/testing/Helicopter%20fuel/' },
    { name: 'Hopping Crowns', imageUrl: '/images/Crownsgpt.webp', link: 'https://gameschakra.com/testing/hopping-crowns/v7/' },
    { name: 'Brick Dodge', imageUrl: '/images/Brickdodgegpt.webp', link: 'https://gameschakra.com/testing/Brick%20Dodge/' },
    { name: 'Tic-Tac-Toe', imageUrl: '/images/tictactoe.png', link: 'https://gameschakra.com/testing/tic-tac-toe/v7/' },
    { name: 'Space Jump', imageUrl: '/images/Spacejumpgpt.webp', link: 'https://gameschakra.com/testing/Space%20Jump/' },
    { name: 'Stickman Fighter', imageUrl: '/images/Stickmanfightergpt.webp', link: 'https://gameschakra.com/testing/stickman%20fighter/' },
    { name: 'Equation', imageUrl: '/images/Equationgpt.webp', link: 'https://gameschakra.com/testing/Equations%20Right%20or%20Wrong!/' },
    { name: 'Jet Halloween', imageUrl: '/images/Jethalloweengpt.webp', link: 'https://gameschakra.com/testing/Jet%20Halloween/' },
    { name: 'Infinite Jumping', imageUrl: '/images/infintejumping.jpeg', link: 'http://gameschakra.com/testing/Infinite%20jumping%20template/' },
    { name: 'Basket', imageUrl: '/images/basket.jpeg', link: 'http://gameschakra.com/testing/basket/' },
    { name: 'Endless Runner', imageUrl: '/images/endlessrunner.jpeg', link: 'http://gameschakra.com/testing/Endless%20runner%20template/' },
    { name: 'Jumper', imageUrl: '/images/Jumpergpt.webp', link: 'https://gameschakra.com/testing/Jumper/' },
    { name: 'Galaxy Stone', imageUrl: '/images/Galaxystonegpt.webp', link: 'https://gameschakra.com/testing/Stone%20in%20Galaxy/' },
    { name: 'Alphabet', imageUrl: '/images/Alphabetgpt.webp', link: 'https://gameschakra.com/testing/Alphabet/' },
    { name: 'Color Box', imageUrl: '/images/Colorboxgpt.webp', link: 'https://gameschakra.com/testing/Color%20Box/' },
    { name: 'Dungeon Box', imageUrl: '/images/Dungeonboxgpt.webp', link: 'https://gameschakra.com/testing/Dungeon%20box/' },
    { name: 'Cutie Tootie Frooti', imageUrl: '/images/Cutietootifrootiegpt.webp', link: 'https://gameschakra.com/testing/CutieTuttiFrutti/' },
    { name: 'Birds Memory', imageUrl: '/images/Birdsmemorygpt.webp', link: 'https://gameschakra.com/testing/Birds%20Memory/' },
    { name: 'Hungry Number', imageUrl: '/images/Hungrynumbersgpt.webp', link: 'https://gameschakra.com/testing/Hungry%20Number/' },
    { name: 'ABC Jump', imageUrl: '/images/Abcjumpgpt.jpeg', link: 'https://gameschakra.com/testing/ABC%20Jump/' },
    { name: 'Animal Fall', imageUrl: '/images/Animalfallgpt.jpeg', link: 'https://gameschakra.com/testing/animal-fall/v1/' },
    { name: 'Hold up the Ball' , imageUrl: '/images/holdtheballgpt2.webp', link: 'https://gameschakra.com/testing/Hold%20up%20the%20Ball/'},
    { name: 'Pacman', imageUrl: '/images/Pacmangpt.webp', link: 'https://gameschakra.com/testing/PacMan/' },
    { name: 'Coloured Circle', imageUrl: '/images/Coloredgpt.webp', link: 'https://gameschakra.com/testing/Colored%20Circle/' },
    { name: 'Shot 2 Dots', imageUrl: '/images/Shottwodotsgpt.webp', link: 'https://gameschakra.com/testing/2%20Dots%20Challenge/' },
    { name: 'Ricochet', imageUrl: '/images/Ricochetgpt.jpeg', link: 'https://gameschakra.com/testing/Richocet/' },
];

export default games;
// { name: 'Hold up the Ball', imageUrl: '/images/holdupballgpt3.jpeg', link: 'https://gameschakra.com/testing/Hold%20up%20the%20Ball/' },
