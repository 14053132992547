import React from 'react';
import './App.css';
import Home from './components/Home';
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Games from "./components/Games";
import About from './components/About';
import Privacypolicy from './components/Privacypolicy';
import ContactUs from './components/ContactUs';
import Termsconditions from './components/Termsconditions';
import DevPortal from './components/DevPortal';
import ScrollToTop from "./components/ScrollToTop";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ErrorBoundary from './components/ErrorBoundary';

function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <ScrollToTop />
      <ErrorBoundary>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/games" element={<Games />} /> 
          <Route path="/about" element={<About />} />
          <Route path="/privacypolicy" element={<Privacypolicy />} />
          <Route path="/termsconditions" element={<Termsconditions />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/devportal" element={<DevPortal />} />
        </Routes>
      </ErrorBoundary>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
