import React from 'react';
import '../Styles/Games.css';
import 'animate.css';
import games from './Data';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGamepad, faMountain, faCrosshairs, faPuzzlePiece, faFutbol } from '@fortawesome/free-solid-svg-icons';

export const GameCard = ({ game }) => {
  const handleGameClick = (e) => {
    e.preventDefault();
    // Directly redirect to the game link
    window.location.href = game.link;
  };

  return (
    <div className="col-6 col-md-4 col-lg-2 mb-4">
      <a href={game.link} onClick={handleGameClick}>
        <div className="card h-100">
          <img src={game.imageUrl} className="card-img-top" alt={game.name} />
          <div className="card-body">
            <h6 className="card-title">{game.name}</h6>
          </div>
        </div>
      </a>
    </div>
  );
};

const Games = () => {
  const headings = ["Arcade", "Shooting", "Puzzle", "Adventure", "Sports"];
  const icons = {
    Arcade: faGamepad,
    Shooting: faCrosshairs,
    Puzzle: faPuzzlePiece,
    Adventure: faMountain,
    Sports: faFutbol
  };

  return (
    <div className="container mt-4">
      {headings.map((heading, rowIndex) => (
        <div key={rowIndex} className="game-section">
          <h4 className="mt-4">
            <FontAwesomeIcon icon={icons[heading]} /> {heading}
          </h4>
          <div className="row row-cols-1 row-cols-md-6 g-4">
            {games.slice(rowIndex * 6, rowIndex * 6 + 6).map((game, index) => (
              <GameCard key={index} game={game} />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Games;
